



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.main_teams_wrapper {
  .main_timeline_wrapper {
    margin-bottom: 50px;

    .steps_container {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 15px;
      @media (min-width: 320px) and (max-width: 1200px) {
        display: none;
      }
      .one_step_wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        position: relative;
        &::after {
          // background-color: #e3e3e3;
          background-color: #f7f7f7;
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          width: 100%;
          height: 4px;
        }
        &.first_step {
          &::after {
            background-color: #e5e5e5;
            background-color: #3c3;
            background-color: #11bfe3;
          }
          .step {
            background-color: #3c3;
            background-color: #11bfe3;
            color: #fff;
          }
        }
        .step {
          position: relative;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          // background: #f7f7f7;
          background-color: #f7f7f7;
          box-shadow: 0 5px 10px rgba(#000, 0.11);
          z-index: 2;
          color: #ccc;
          text-align: center;
          line-height: 30px;
          display: block;

          &::before {
            position: absolute;
            content: "";
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: -30px;
            border-width: 15px;
            border-color: transparent transparent #f7f7f7 transparent;
            border-style: solid;
          }
        }
      }
    }
    .all_steps_cards_container {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .every_card_step {
        background-color: #fff;
        width: 25%;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(#000, 0.11);
        transition: 0.3s;
        color: #777;
        transition: 0.5s;
        padding: 25px 10px 10px;
        height: 100%;
        min-height: 285px;

        display: block;
        cursor: pointer;
        h4 {
          margin: 0 0 15px;
          text-transform: capitalize;
          transition: 0.5s;
          font-size: 18px;
          cursor: pointer;
          text-align: center;
        }
        p {
          margin: 0;
          line-height: 1.7;
          cursor: pointer;
        }
        &:hover {
          h4 {
            color: #23ccef;
            cursor: pointer;
          }
        }
        // }
        // .router_cards {
        .router_cards {
          color: #777;
          transition: 0.5s;

          height: 100%;

          display: block;
          cursor: pointer;
          h4 {
            margin: 0 0 15px;
            text-transform: capitalize;
            transition: 0.5s;
            font-size: 18px;
            cursor: pointer;
            position: relative;
            &::first-letter {
              font-weight: bold;
              font-size: 20px;
            }
          }
          p {
            margin: 0;
            line-height: 1.7;
            cursor: pointer;
          }
          &:hover {
            h4 {
              color: #23ccef;
              cursor: pointer;
            }
          }
        }
        @media (min-width: 320px) and (max-width: 1200px) {
          min-width: 300px;

          margin-bottom: 10px;
        }
      }
      @media (min-width: 320px) and (max-width: 1200px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  // Start All Teams
  .header_contact_wrapping {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (min-width: 320px) and (max-width: 480px) {
      display: block;
    }
  }
  .header_buttons_wrapper {
    display: flex;
    gap: 10px;
    @media (min-width: 320px) and (max-width: 480px) {
      margin-top: 10px;
    }
    .add_contact_buttons {
      @media (min-width: 320px) and (max-width: 400px) {
        min-width: 130px;
        padding: 8px;
        font-size: 15px;
      }
    }
  }
  .all_teams_container {
    margin-top: 2rem;
    .every_team {
      margin: 2rem 0;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
      @media (min-width: 320px) and (max-width: 480px) {
        display: block;
      }
      .main_route {
        @media (min-width: 320px) and (max-width: 480px) {
          margin-bottom: 10px;
        }
        .team_name {
          color: #777;
          text-decoration: none;
          font-size: 1.2rem;
          text-transform: capitalize;
          transition: 0.3s;
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
            cursor: no-drop;
          }
          &:hover {
            color: #11bfe3;
          }
        }
        .team_name_test {
          color: #777;
          text-decoration: none;
          font-size: 1.2rem;
          text-transform: capitalize;
          transition: 0.3s;

          cursor: pointer;
          &.disabled {
            opacity: 0.5;
          }
          &:hover {
            color: #11bfe3;
          }
        }
      }
      .main_actions {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        .actions-icons {
          font-size: 14px;
          width: 30px;
          height: 30px;
          box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
          text-align: center;
          line-height: 30px;
          border-radius: 50%;
          cursor: pointer;
          color: #777;
          transition: 0.3s;
          &.team_count {
            background-color: #11bfe3;
            color: #fff;
          }
          &.add {
            &:hover {
              background-color: #11bfe3;
              color: #fff;
            }
          }
          &.edit {
            &:hover {
              background-color: #ffa534;
              color: #fff;
            }
          }
          &.reinvite {
            &:hover {
              background-color: #3c3;
              color: #fff;
            }
          }
          &.delete {
            &:hover {
              background-color: #fb404b;
              color: #fff;
            }
          }
        }
      }
    }
  }
  // End All Teams
  .text_area_desc {
    height: 150px !important;
    line-height: 1.7 !important;
    padding: 10px 15px;
    resize: none;
    color: #606266;
    width: 100%;
    border: 1px solid #dcdfe6;
    outline: none;
    transition: 0.3s;
    border-radius: 4px;
    word-break: break-word;
    &:focus {
      border-color: #11bfe3;
    }
    &::placeholder {
      color: rgba(#606266, 0.5);
      font-size: 14px;
      font-weight: 400;
    }
  }
}

// Start Collapse
::v-deep .el-collapse-item__header {
  height: auto;
  cursor: pointer;
  line-height: 38px;
  .wrapper_collapse_for_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    @media (min-width: 320px) and (max-width: 480px) {
      display: block;
      padding: 10px 0;
    }
    // .head_part_one {
    // }
  }
  .head_part_two {
    .main_actions {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .actions-icons {
        font-size: 14px;
        width: 30px;
        height: 30px;
        box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        cursor: pointer;
        color: #777;
        transition: 0.3s;
        &.team_count {
          background-color: #11bfe3;
          color: #fff;
        }
        &.add {
          &:hover {
            background-color: #11bfe3;
            color: #fff;
          }
        }

        &.edit {
          &:hover {
            background-color: #ffa534;
            color: #fff;
          }
        }
        &.reinvite {
          &:hover {
            background-color: #3c3;
            color: #fff;
          }
        }
        &.delete {
          &:hover {
            background-color: #fb404b;
            color: #fff;
          }
        }
      }
    }
  }
}
.team_description {
  padding-top: 1rem;
  font-size: 16px;
  color: #777;
}
.all_details_for_team_members {
  .every_team_members {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    position: relative;
    @media (min-width: 320px) and (max-width: 480px) {
      display: block;
    }
    .part_a_member {
      min-width: 100px;
      @media (min-width: 320px) and (max-width: 480px) {
        margin-bottom: 15px;
      }
    }
    .part_b_member {
      flex-grow: 1;
      .main-scores-container {
        display: flex;
        justify-content: space-between;

        .every-score {
          gap: 7px;
          .score-word {
            color: #777;
          }
          .label {
            padding: 0.5rem;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            text-align: center;
            color: #fff;
            &.precision {
              background-color: #3c3;
            }

            &.technical_reasoning {
              background-color: #f93;
            }

            &.sequence {
              background-color: #39c;
            }

            &.confluence {
              background-color: #c36;
            }
          }
        }
      }
    }
    .part_c_member {
      .main_actions {
        display: flex;
        justify-content: center;
        min-width: 50px;
        gap: 10px;
        .actions-icons {
          font-size: 14px;
          width: 30px;
          height: 30px;
          box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
          text-align: center;
          line-height: 30px;
          border-radius: 50%;
          cursor: pointer;
          color: #777;
          transition: 0.3s;
          &.team_count {
            background-color: #11bfe3;
            color: #fff;
          }
          &.add {
            &:hover {
              background-color: #11bfe3;
              color: #fff;
            }
          }

          &.edit {
            &:hover {
              background-color: #ffa534;
              color: #fff;
            }
          }
          &.reinvite {
            &:hover {
              background-color: #3c3;
              color: #fff;
            }
          }
          &.delete {
            &:hover {
              background-color: #fb404b;
              color: #fff;
            }
          }
        }
      }
      @media (min-width: 320px) and (max-width: 480px) {
        position: absolute;
        top: 15px;
        right: 10px;
      }
    }
  }
}
.main_invitation_members_wrapper {
  .every_invitation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem 0 0.5rem;

    .part_i {
      display: flex;
      gap: 5px;
      align-items: center;
      color: #777;
      .email_address {
        opacity: 0.5;
      }
      .email_icon {
        color: #11bfe3;
      }
    }
    .part_ii {
      .actions-icons {
        font-size: 14px;
        width: 30px;
        height: 30px;
        box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        cursor: pointer;
        color: #777;
        transition: 0.3s;
        &.delete {
          &:hover {
            background-color: #fb404b;
            color: #fff;
          }
        }
      }
    }
  }
}
// // End Collapse
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
